:root{
    --primary:#C8371B;
    --white:#fff;
    --body-text:#636363;
    --black:#000000;
    --grey:#757575;
    --grey-100:#4B4B4B;
    --grey-200:#7C8088;
    --balck-100:#17191C;
    --grey-300:#484848;
}