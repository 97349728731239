.owl-carousel .owl-item img {
    display: inline-block;
    width: auto;
}
.owl-theme .owl-dots .owl-dot span {
    width: 12px;
    height: 12px;
    background: #BCBCBC;
    opacity: 0.5;
}
.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
    background: #c8371b;
    opacity: 1;
    height: 14px;
    width: 14px;
    position: relative;
    top: 1px;
}


.step-progress-container::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    top: 50%;
    transform: translateY(-50%);
    background: #D3D3D3;
    border-radius: 5px;
}

.step-progress-container, .step-progress {
    position: fixed;
    border-radius: 5px;
    border-radius: 5px;
    position: fixed;
    height: 5px;
    width: 100%;
    margin-top: 0px;
    top: 47px;
    left: 0;
    right: 0;
    z-index: 9;
}

  
  .step-progress {
    background: #C8371B;
    width: 0;
    transition: width 0.4s linear;
  }
  
  .percentage {
    background-color: #4B4B4B;
    border-radius: 5px;
    box-shadow: 0 4px 5px rgb(0, 0, 0, 0.2);
    color: #fff;
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    padding: 4px;
    position: absolute;
    top: 20px;
    left: 0;
    transform: translateX(-50%);
    width: 28px;
    text-align: center;
    transition: left 0.4s linear;
}

.percentage::after {
    background-color: #4B4B4B;
    content: '';
    position: absolute;
    top: -5px;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    height: 10px;
    width: 10px;
    z-index: -1;
}

.stepper-content {
    margin-top: 32px;
    height: 74vh;
    overflow: hidden;
    overflow-y: auto;
}
.mb-30 {
    margin-bottom: 24px;
}
.mb-40{
    margin-bottom: 40px;
}
.mt-40 {
    margin-top: 40px;
}
.pt-72{
    padding-top: 72px;
}
.mb-20{
    margin-bottom: 20px;
}
.mb-32 {
    margin-bottom: 32px;
}
.login-border{
    border: 1px solid transparent;
}
.container-inner{
    max-width:1000px;
}

.absolute-otp{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(82px);
    background: #fff;
    box-shadow: 0px 24px 50px rgba(0, 0, 0, 0.15);
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}

.stepper-content::-webkit-scrollbar{
	width: 10px;
	background-color: transparent;
    transition: all 0.8s ease-in-out;
    -webkit-transition: all 0.8s ease-in-out;
}
.stepper-content::-webkit-scrollbar-thumb{
	background-color: transparent;
    transition: all 0.8s ease-in-out;
    -webkit-transition: all 0.8s ease-in-out;
    border: 3px solid transparent;
    background-clip: content-box;
}

.stepper-content::-webkit-scrollbar {
    width: 10px;
    transition: all 0.8s ease-in-out;
    -webkit-transition: all 0.8s ease-in-out;
    background: transparent;
}
.stepper-content:hover::-webkit-scrollbar-thumb{
	background-color: #D7DAE1;
    border-radius: 10px;
    transition: all 0.8s ease-in-out;
    -webkit-transition: all 0.8s ease-in-out;
    border: 3px solid transparent;
    background-clip: content-box;    
}

.stepper-content:hover::-webkit-scrollbar {
    width: 10px;
    transition: all 0.8s ease-in-out;
    -webkit-transition: all 0.8s ease-in-out;
    background: transparent;
}
.stepper-content:hover::-webkit-scrollbar-track {
    background-image: url(../../images/scrolltrack.png);
    background-repeat: repeat-y;
    background-size: 1px 1px;
    background-position: center;
    transition: all 0.8s ease-in-out;
    -webkit-transition: all 0.8s ease-in-out;
}

.stepper-content.more-width::-webkit-scrollbar {
    width: 10px;
    transition: all 0.8s ease-in-out;
    -webkit-transition: all 0.8s ease-in-out;
    background: transparent;
}
.stepper-content:hover::-webkit-scrollbar-thumb:hover{
	background-color: #D7DAE1;
    border-radius: 15px;
    transition: all 0.8s ease-in-out;
    -webkit-transition: all 0.8s ease-in-out;
    border: 0;
}
.stepper-content:hover::-webkit-scrollbar-track:hover {
    background-image: url(../../images/scrolltrack.png);
    background-repeat: repeat-y;
    background-size: 2px 2px;
    background-position: center;
    transition: all 0.8s ease-in-out;
    -webkit-transition: all 0.8s ease-in-out;
}

/* global */
/* ::-webkit-scrollbar{
	width: 6px;
	background-color: transparent;
    transition: all 0.8s ease-in-out;
    -webkit-transition: all 0.8s ease-in-out;
}
::-webkit-scrollbar-thumb{
	background-color: transparent;
    transition: all 0.8s ease-in-out;
    -webkit-transition: all 0.8s ease-in-out;
}

::-webkit-scrollbar {
    width: 5px;
    transition: all 0.8s ease-in-out;
    -webkit-transition: all 0.8s ease-in-out;
    background: transparent;
} */
::-webkit-scrollbar-thumb{
	background-color: #D7DAE1;
    border-radius: 10px;
    transition: all 0.8s ease-in-out;
    -webkit-transition: all 0.8s ease-in-out;
    border: 3px solid transparent;
    background-clip: content-box;
}

::-webkit-scrollbar {
    width: 10px;
    transition: all 0.8s ease-in-out;
    -webkit-transition: all 0.8s ease-in-out;
    background: transparent;
}
::-webkit-scrollbar-track {
    background-image: url(../../images/scrolltrack.png);
    background-repeat: repeat-y;
    background-size: 1px 1px;
    background-position: center;
    transition: all 0.8s ease-in-out;
    -webkit-transition: all 0.8s ease-in-out;
}

::-webkit-scrollbar:hover {
    width: 10px;
    transition: all 0.8s ease-in-out;
    -webkit-transition: all 0.8s ease-in-out;
    background: transparent;
}
::-webkit-scrollbar-thumb:hover{
	background-color: #D7DAE1;
    border-radius: 15px;
    transition: all 0.8s ease-in-out;
    -webkit-transition: all 0.8s ease-in-out;
    border: 0;
}
::-webkit-scrollbar-track:hover {
    background-image: url(../../images/scrolltrack.png);
    background-repeat: repeat-y;
    background-size: 2px 2px;
    background-position: center;
    transition: all 0.8s ease-in-out;
    -webkit-transition: all 0.8s ease-in-out;
}

/* border: 3px solid transparent;

border-radius: 9px;

background-clip: content-box; */