/* styles/tailwind.css */

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

:root {
  --color-primary-main: #c8271b;
  --color-primary-dark: #d02d14;
  --color-primary-light: #fe583e;
  --color-success-main: #45c4a0;
  --color-success-light: #52d8a0;
  --color-success-dark: #34ba8c;
  --color-warning-main: #fab758;
  --color-warning-light: #9e69fd;
  --color-warning-dark: #804bdf;
  --color-error-main: #e2626b;
  --color-error-light: #9e69fd;
  --color-error-dark: #804bdf;
  --color-background-default: #ffffff;
  --color-info-main: #5eb9f0;
  --color-info-light: #9e69fd;
  --color-info-dark: #804bdf;
  --color-secondary-main: #d3d3d3;
  --color-secondary-light: #9e69fd;
  --color-secondary-dark: #804bdf;
  --color-text-default: #000000;
}
