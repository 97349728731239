html,
body {
  padding: 0;
  margin: 0;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    Fira Sans,
    Droid Sans,
    Helvetica Neue,
    sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.MuiInputBase-input.MuiOutlinedInput-input {
  padding-left: 10px;
}
/* 
.MuiInputBase-root {
  width: 250px;
} */

.MuiButtonBase-root.MuiButton-root.Mui-disabled {
  pointer-events: all;
  cursor: not-allowed;
}

span[aria-label="Clear search"] {
  cursor: pointer;
}

.MuiInputBase-input:active .MuiInputBase-root span[aria-label="Clear search"] {
  display: "block";
}

@keyframes shimmerAnimation {
  0% {
    background-position: -400px 0;
  }
  25% {
    background-position: -200px 0;
  }
  50% {
    background-position: 0 0;
  }
  75% {
    background-position: 200px 0;
  }
  100% {
    background-position: 400px 0;
  }
}

.navigation-wrapper {
  position: relative;
}

.instance-arrow {
  width: 35px;
  height: 35px;
  padding: 10px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  fill: #5d5d5d;
  background-color: #d3d3d3;
  border-radius: 50%;
  cursor: pointer;
}

.instance-arrow--left {
  left: 0;
}

.instance-arrow--right {
  left: auto;
  right: 0;
}

.instance-arrow--disabled {
  display: none;
}

.step-form-container {
  width: 100%;
}

.flex-column-start {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.MuiTablePagination-root.MuiBox-root.css-8k4lth {
  justify-content: flex-end;
}

.css-hcf2tr-MuiSelect-select-MuiInputBase-input-MuiInput-input.css-hcf2tr-MuiSelect-select-MuiInputBase-input-MuiInput-input.css-hcf2tr-MuiSelect-select-MuiInputBase-input-MuiInput-input {
  text-align: left;
}

.sumsubsdk {
  max-height: 80vh;
  overflow: auto;
}
