
@import './var.css';

/*-- Common Style --*/

*, *::after, *::before {
  box-sizing: border-box;
}

* {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
  
html, body {
    height: 100%;
    width: 100%;
}
body{
    font-size: 16px;
    overflow: hidden;
    overflow-y: auto;
    margin: 0;
    padding: 0;
    background-color: var(--white);
    font-family: 'Poppins', sans-serif;
    color: var(--body-text);
    font-weight: 400;
} 
h1, h2, h3, h4, h5, h6 {
    font-weight: 400;
    margin-bottom: 0;
    line-height: 1.5;
    font-family: 'Poppins', sans-serif;
    color:var(--black);
}
h1,.h2 {
    font-size: 48px;
}
h2 {
    font-size: 40px;
    line-height: 36px;
}
h3 {
    font-size: 24px;
    line-height: 32px;
}
h4 {
    font-size: 24px;
    line-height: 50px;
}
h5 {
    font-size: 18px;
}
h6 {
    font-size: 16px;
}
.fs-12{
    font-size: 12px !important;
}
p{
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
}
a, button {
    color: var(--primary);
    line-height: inherit;
    text-decoration: none;
    cursor: pointer;
}
a, button, img, input, span {
    transition: all 0.3s ease 0s;
}

a,span{
    display:inline-block;
}

a:hover {
    color: var(--primary);
    text-decoration: none;
}
button, input[type="submit"] {
    cursor: pointer;
}
ul {
    list-style: outside none none;
    margin: 0;
    padding: 0;
}
.fw-500{
    font-weight:500;
}
.fw-600{
    font-weight: 600;
}
.fw-700{
    font-weight: 700;
}
.fw-800{
    font-weight: 800;
}

.btn {
    border-radius: 5px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    padding: 12px 10px;
    text-align: center;
}

.primary-btn {
    background: #C8371B;
    border: 1px solid #C8361A;
    color: #FFFFFF;
}
.primary-btn:hover,.primary-btn:focus,.primary-btn:active {
    background: #C8371B;
    color: #FFFFFF;
}
.white-btn-outline{
    border: 1px solid #4B4B4B;
    color: #4B4B4B;
    background-color: transparent;
}
.white-btn-outline:hover, .white-btn-outline:focus,.white-btn-outline:active{
    color: #fff;
    background-color:#4B4B4B;
}
.z-9{
    z-index: 999;
}

::placeholder{
    color:#757575;
}

.form-check {
    display: block !important;
    min-height: 1.5rem !important;
    padding-left: 1.5em !important;
    margin-bottom: -0.875rem !important;
}
.step-content-tab{
  margin-bottom:100px;
}

.required{
  color: #c8371b;
}

img.img-fluid.switch-ico.ind-light {
    width:47px;
}
img.img-fluid.switch-ico.ind-dark  {
    width:47px;
}

img.img-fluid.inactive {
    filter: grayscale(1);
}

button.btn.profile-icon {
    margin-left: auto;
    align-items: center;
    width: 36px;
    height: 36px;
    border-radius: 100%;
    border: 1px solid #d3d3d3;
    display: flex;
    justify-content: center;
    background: #d3d3d3;
  }

  .dark-text-800{
    color: #484848 !important;
  }

  .ace-bnr-title{
    font-size: 40px;
    line-height: 48px;
  }
  .ace-bnr-desc{
    font-size: 18px;
    line-height: 28px;
  }

  .step-col .step-item h5 {
    display: none;
  }
  .step-col.active .step-item h5 {
    display: block;
  }

  a.success-text.copiedPass:hover {
    color: #34A853 !important;
}

 